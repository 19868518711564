import sliderImg01 from "../images/slider1.png";
import sliderImg02 from "../images/slider2.png";
import sliderImg03 from "../images/slider03.png";
import sliderImg04 from "../images/slider04.png";
import sliderImg05 from "../images/slider05.png";

export const sliderData = [
  {
    id: "01",
    title: "We have simple and delicious food for you",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi animi, itaque ipsum magnam quae alias omnis veniam dolor fugiat similique voluptas consectetur corrupti odit quisquam incidunt quia architecto voluptate molestiae?",
    imgUrl: sliderImg01,
  },
  {
    id: "02",
    title: "We believe good food over great smile",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi animi, itaque ipsum magnam quae alias omnis veniam dolor fugiat similique voluptas consectetur corrupti odit quisquam incidunt quia architecto voluptate molestiae?",
    imgUrl: sliderImg02,
  },
  {
    id: "03",
    title: "Meet, Eat and Enjoy the true test",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi animi, itaque ipsum magnam quae alias omnis veniam dolor fugiat similique voluptas consectetur corrupti odit quisquam incidunt quia architecto voluptate molestiae?",
    imgUrl: sliderImg03,
  },
  {
    id: "04",
    title: "Our believe good food with a smile",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi animi, itaque ipsum magnam quae alias omnis veniam dolor fugiat similique voluptas consectetur corrupti odit quisquam incidunt quia architecto voluptate molestiae?",
    imgUrl: sliderImg04,
  },
  {
    id: "05",
    title: "Meet, Eat and Enjoy the true test",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi animi, itaque ipsum magnam quae alias omnis veniam dolor fugiat similique voluptas consectetur corrupti odit quisquam incidunt quia architecto voluptate molestiae?",
    imgUrl: sliderImg05,
  },
];
